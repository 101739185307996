import { useState, useRef, useEffect, useMemo, useReducer } from 'react';
import './App.scss';
import Inputs from './Inputs';
import { defaultInputModel, ResultsModel, emptyValidationInputs, MessagesModel } from './models/InputModel';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Results from './Results';
import { getAvailableDateRange, isMocked, updateBusinessDayCount, updateHolidayDates } from './services/CalcService';
import { checkInvalidInput } from './data/Validation';
import { inputReducer } from './data/InputReducer';
import Disclaimers from './Disclaimers';
import MyMenu from './components/MyMenu';
import MenuIcon from '@mui/icons-material/Menu';
import MarkdownDialog from './components/MarkdownDialog';
import { Divider } from '@mui/material';
import { APIHolidayDatesResponse, APIResponse, CalcResponse } from './models/ResponseModel';
import { utc } from 'moment';
import { dataDateFormat } from './data/DateUtils';

function App() {
  const [inputs, dispatchInputs] = useReducer(inputReducer, defaultInputModel());
  const [validationInputs, setValidationInputs] = useState(emptyValidationInputs());
  const [results, setResults] = useState<ResultsModel | null>(null);
  const [messages, setMessages] = useState<MessagesModel>({});
  const [rnDialogOpen, setRNDialogOpen] = useState(false);
  
  const calculateController = useRef<AbortController | null>(null);

  function setApiErrorMessage(message: string | null) {
    if (message) {
      setMessages({ apiErrorMessage: message });
    } else {
      setMessages({});
    }
  }

  function onCalculate() {
    dispatchInputs({ type: "calculate", setResults, setApiErrorMessage, controller: calculateController });
  }

  function onReset() {
    dispatchInputs({ type: "reset", setResults });
  }

  useEffect(() => {
    const controller = new AbortController();
    updateBusinessDayCount(inputs, setValidationInputs, setApiErrorMessage, controller);
    return () => controller.abort();
  }, [inputs.interestMethod, inputs.commitment[0].effectiveDate, inputs.endDate, inputs.selectedCountryForHolidays]);

  useEffect(() => {
    const controller = new AbortController(); 
    updateHolidayDates(inputs, setValidationInputs, setApiErrorMessage, controller);
    return () => controller.abort();
  }, [inputs.selectedCountryForHolidays]);

  useEffect(() => {
    const controller = new AbortController(); 
    getAvailableDateRange(inputs, setValidationInputs, setApiErrorMessage, controller);
    return () => controller.abort();
  }, [inputs.interestMethod, inputs.lookBack, inputs.lockout, inputs.selectedCountryForHolidays, inputs.RFR]);

  useEffect(() => {
    if (isMocked) {
      (globalThis as any).mockHolidayDates = (mockResult: APIHolidayDatesResponse) => {
        const holidayDates = mockResult.HolidayDates.map(d => utc(d, dataDateFormat));
        setValidationInputs(vi => ({ ...vi, holidayDates }));
      };
      (globalThis as any).mockCalculate = (mockResult: CalcResponse) => {
        setResults({
          inputs,
          response: mockResult
        });
      };
    }
  }, [inputs]);

  const validationResults =
    useMemo(() =>
      checkInvalidInput(inputs, validationInputs),
      [inputs, validationInputs]
    );

  //function getSavedInputsOrDefault() {
  //  try {
  //    const savedInputs = localStorage.getItem("inputs");
  //    if (savedInputs != null) {
  //      return JSON.parse(savedInputs);
  //    }
  //  }
  //  catch {
  //    console.log("Failed to reload saved RFR Calculator input state, ignoring.");
  //  }
  //  return defaultInputModel();
  //}

  //useEffect(() => {
  //  const json = JSON.stringify(inputs);
  //  localStorage.setItem("inputs", json);
  //}, [inputs])

  function onClickReleaseNotes() {
    setRNDialogOpen(true);
  }

  function onClickDocument(link: string) {
    if (link.startsWith("assets/")) {
      link = window.location.href.replace("index.html", "") + link;
    }
    console.log("opening", link);
    window.open(link);
  } 

  return (
    <>
      <div className="toolbar">
        <div className="disclaimers">
          <div className="headerText disclaimer">
            Detailed compounding and simple interest calculations for SOFR, SONIA, ESTR, TONAR, SORA, SARON, and CORRA.
          </div>
          <div className="headerText disclaimer headerBanner">
            <div className="releaseTagLine">Coming Soon: Inclusive Interest and Interest Period Weighted Shift.</div>
            <a id="releaseNotesBannerLink" onClick={onClickReleaseNotes}>Release Notes</a>
          </div>
          <div className="headerText disclaimer">
            For all licensing and support issues please contact <a href="mailto:mwso-productsupport@ihsmarkit.com">mwso-productsupport@ihsmarkit.com</a>
          </div>
        </div>
        <div className="headerSpacer"></div>
        <MyMenu name="top" buttonContent={<MenuIcon />}
          items={[
            { key: "iborSolutionsMenuItem", label: "IHS Markit IBOR Solutions", onClick() { onClickDocument('https://ihsmarkit.com/topic/ibor.html') } },
            { key: "liborReplacementDataMenuItem", label: "LIBOR Replacement Data", onClick() { onClickDocument('https://cdn.ihsmarkit.com/www/prot/pdf/0420/LIBOR-Replacement-Data-factsheet.pdf') } },
            { key: "tradingWithBalanceTransferMenuItem", label: "Trading with Balance Transfer", onClick() { onClickDocument('assets/Compounding in Arrears - Trading with Balance Transfer.pdf') } },
            { key: "releaseNotesMenuItem", label: "Release Notes", onClick: onClickReleaseNotes },
            { key: "helpMenuItem", label: "Help", onClick() { onClickDocument('assets/Risk-Free Rates Calculator Help.pdf') } },
            { key: "contactUsMenuItem", label: "Contact Us", onClick() { onClickDocument('https://ihsmarkit.com/Info/global-ibor-transition-contact-us.html') } },
          ]}
        />
      </div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Inputs inputs={inputs} dispatchInputs={dispatchInputs} validationInputs={validationInputs} validationResults={validationResults} results={results} onCalculate={onCalculate} onReset={onReset} />
        <Disclaimers inputs={inputs} validationInputs={validationInputs} results={results} />
        <div className="apiErrorMessage rateDisclaimer">{messages.apiErrorMessage}</div>
        <Divider />
        <Results results={results} />
      </LocalizationProvider>
      <MarkdownDialog title="Release Notes" open={rnDialogOpen} setOpen={setRNDialogOpen} sourceUrl="assets/ReleaseNotes.md" />
    </>
  );
}

export default App;
